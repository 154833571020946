<template>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav text-dark">
            <p class="user-name font-weight-bolder mb-0">
              Marisa Kirisame
            </p>
            <span class="user-status">User</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{name: 'profile'}">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
</template>

<script>
    import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, } from 'bootstrap-vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import useJwt from '@/auth/jwt/useJwt'
    import httpConfigHelper from '@/libs/services/config'

    export default {
        components: {
            BNavItemDropdown,
            BDropdownItem,
            BDropdownDivider,
            BAvatar
        },
        data() {
          return {
            config: new httpConfigHelper
          }
        },
        methods: {
            logout() {
                useJwt.logout().then(response => {
                    this.redirectToLogin()
                }).catch(ex => {
                    this.redirectToLogin()
                })
            },
            async redirectToLogin() {
                useJwt.removeToken()
                // Remove other storage values here as well.
                this.config.removeCustomStorageKeys()
                window.location.replace('http://tiongbahru.net')
                // await this.$router.replace({'name':'auth-login'}).then(() => {
                //     this.$toast({
                //         component: ToastificationContent,
                //         position: 'top-right',
                //         props: {
                //             title: 'Logged Out',
                //             icon: 'KeyIcon',
                //             variant: 'success',
                //             text: 'You have successfully logged out.'
                //         }
                //     })
                // }).catch(ex => {
                //     this.$refs.loginForm.setErrors(ex)
                // })
            }
        }
    }
</script>