<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <!-- Custom Item -->
    <li class="nav-item" @click="logout">
      <b-link class="d-flex align-items-center">
        <feather-icon icon="LogOutIcon"/>
        <span class="menu-title text-truncate">Log Out</span>
      </b-link>
    </li>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
// Begin custom imports
import { BLink, BBadge } from 'bootstrap-vue'
import FeatherIcon from '../../../../../../components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import httpConfigHelper from '@/libs/services/config'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    // Custom components
    BLink,
    BBadge,
    FeatherIcon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  data() {
    return {
      config: new httpConfigHelper
    }
  },
  methods: {
    logout() {
        useJwt.logout().then(response => {
            this.redirectToLogin()
        }).catch(ex => {
            this.redirectToLogin()
        })
    },
    async redirectToLogin() {
        useJwt.removeToken()
        // Remove other storage values here as well.
        this.config.removeCustomStorageKeys()
        await this.$router.replace({'name':'auth-login'}).then(() => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Logged Out',
                    icon: 'KeyIcon',
                    variant: 'success',
                    text: 'You have successfully logged out.'
                }
            })
        }).catch(ex => {
            this.$refs.loginForm.setErrors(ex)
        })
    }
  }
}
</script>
