<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems: [],
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  created() {
    const userlevel = useJwt.getPermission()
    this.navMenuItems.splice(0)
    const c = {
      // 0: [
      //   {
      //     header: 'System Setup',
      //   },
      // ],
      // 1: [
      //   {
      //     header: 'Account Navigation',
      //   },
      // ],
      // 2: [
      //   {
      //     header: 'VPN Account Navigation',
      //   },
      // ],
      // 3: [
      //   {
      //     header: 'Settings',
      //   },
      // ],
      // 4: [
      //   {
      //     header: 'Others',
      //   },
      // ],
    }

    // Add Default Dashboard
    var permissionLevel = useJwt.jwtConfig.getItem()
    var navitems = [
      {title: 'Dashboard', route: 'dashboard', icon: 'LayersIcon'},
      {title: 'Profile', route: 'profile', icon: 'UserIcon'},
    ]
    var usernavitems = [
      {title: 'Orders', route: 'orders', icon: 'ArchiveIcon'},
      {title: 'Addresses', route: '', icon: 'HomeIcon'},
      {title: 'Payment Methods', route: '', icon: 'CreditCardIcon'}
    ]
    var adminnavitems = [
      {title: 'Orders', route: 'orders', icon: 'ArchiveIcon'},
      {title: 'Inventory', route: 'admin-inventory', icon: 'ListIcon'},
      {title: 'Users', route: '', icon: 'UsersIcon'},
      {title: 'Stores', route: 'admin-stores', icon: 'ShoppingBagIcon'}
    ]
    navitems.forEach(item => {
      this.navMenuItems.push(item)
    })

    if (permissionLevel.permission == 1) {
      adminnavitems.forEach(item => {
        this.navMenuItems.push(item)
      })
    }

    if (permissionLevel.permission == 2) {
      usernavitems.forEach(item => {
        this.navMenuItems.push(item)
      })
    }

    this.$router.options.routes.forEach(route => {
      let valid = true
      if (route.path == '*') {
        valid = false
      }
      if (
        route.name == null
        || route.meta.permission == null
        || route.name == 'dashboard'
      ) {
        valid = false
      }

      if (valid) {
        if (route.meta.permission.indexOf(userlevel) === -1) {
          valid = false
        }
      }
      if (valid) {
        for (let w = 0; w < Object.keys(c).length; w++) {
          if (route.meta.navHeader == c[w][0].header) {
            if (route.meta.navGroupTitle != null) {
              // Push Main Menu
              if (c[w][1] == undefined) {
                c[w].push({
                  title: route.meta.navGroupTitle,
                  icon: route.meta.navGroupIcon,
                  children: [],
                })
              }

              if (c[w][1].title == route.meta.navGroupTitle) {
                c[w][1].children.push({
                  title: route.meta.pageTitle,
                  route: route.name,
                  icon: route.meta.pageIcon,
                })
              } else {
                // Check if Exists
                let exists = false
                for (let y = 1; y < c[w].length; y++) {
                  if (c[w][y].title == route.meta.navGroupTitle) {
                    exists = true
                    c[w][y].children.push({
                      title: route.meta.pageTitle,
                      route: route.name,
                      icon: route.meta.pageIcon,
                    })
                  }
                }
                if (!exists) {
                  c[w].push({
                    title: route.meta.navGroupTitle,
                    icon: route.meta.navGroupIcon,
                    children: [
                      {
                        title: route.meta.pageTitle,
                        route: route.name,
                        icon: route.meta.pageIcon,
                      },
                    ],
                  })
                }
              }
            } else {
              c[w].push({
                title: route.meta.pageTitle,
                route: route.name,
                icon: route.meta.pageIcon,
              })
            }
          }
        }
      }
    })
    for (let z = 0; z < Object.keys(c).length; z++) {
      if (c[z].length > 1) {
        c[z].forEach(element => {
          this.navMenuItems.push(element)
        })
      }
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
