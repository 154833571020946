<template>
  <div class="navbar-container d-flex content align-items-center bg-white py-2">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
    </div>

    <!-- Right Column -->
    <b-navbar-nav class="bookmark-wrapper nav align-items-center ml-auto">
      <!-- <dark-toggler/> -->
      <cart-dropdown v-if="showCartIcon == true"/>
      <user-control/>
    </b-navbar-nav>
    
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import CartDropdown from './CartDropdown.vue'
import UserControl from './UserControl.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BNavbarNav,
    CartDropdown,
    UserControl,
    DarkToggler,
    // Navbar Components
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    var permission = useJwt.jwtConfig.getItem()
    if (this.$route.path == '/checkout' || permission.permission == 1) {
      this.showCartIcon = false
    }
    else {
      this.showCartIcon = true
    }
  },
  data() {
    return {
      showCartIcon: true
    }
  },
  watch: {
    $route(to, from) {
      var permission = useJwt.jwtConfig.getItem()
      if (to.path == '/checkout' || permission.permission == 1) {
        this.showCartIcon = false
      }
      else {
        this.showCartIcon = true
      }
    }
  },
  methods: {
  },
}
</script>
